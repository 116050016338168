// extracted by mini-css-extract-plugin
export const text = "index-module--text--hMV5y";
export const bodyText = "index-module--bodyText--172Mv";
export const navText = "index-module--navText--8GtUW";
export const memberName = "index-module--memberName--ORAxS";
export const memberBody = "index-module--memberBody--szLwV";
export const contactText = "index-module--contactText--ckcoL";
export const link = "index-module--link--1aDmC";
export const container = "index-module--container--OSMII";
export const home = "index-module--home--5VVUf";
export const about = "index-module--about--Lvj3S";
export const contact = "index-module--contact--tXEoE";
export const homeSmall = "index-module--homeSmall--3YNGL";
export const aboutSmall = "index-module--aboutSmall--IsKQE";
export const contactSmall = "index-module--contactSmall--LfhMb";
export const topBar = "index-module--topBar--2JdQw";
export const navBar = "index-module--navBar--2eWnm";
export const button = "index-module--button--1khAT";
export const padS = "index-module--padS--3ntY9";
export const padMed = "index-module--padMed--1F1rk";
export const memberCard = "index-module--memberCard--1CbwQ";
export const memberImage = "index-module--memberImage--18Uej";
export const memberCardList = "index-module--memberCardList--1NT3q";
export const socialIcons = "index-module--socialIcons--2Jq-7";
export const formField = "index-module--formField--3W6OV";
export const long = "index-module--long--3rO6s";
export const row = "index-module--row--3SiBx";
export const form = "index-module--form--1KKDn";
export const contactSection = "index-module--contactSection--136Jj";
export const navMenu = "index-module--navMenu--3uHRT";
export const navMenuItems = "index-module--navMenuItems--21S9V";